<template>
  <div style="background-color: #ffe1c5">
    <img
      v-if="data.productThumbnailImageUrl"
      class="image"
      :src="data.productThumbnailImageUrl"
      alt=""
    />
    <img
      v-if="!data.productThumbnailImageUrl"
      class="image"
      src="../../assets/images/project2/productImg.png"
      alt=""
    />
    <div class="infoContainer"  v-if="data.batchData&&data.batchData.status==0">
      <div class="info_bottom">
        <img
          src="https://asset.fsytss.com/trace/wcj/true.gif"
          class="info_bottomImg"
        />
        <div class="info_bottomLeft">

          <p class="title">真品验证通过！</p>
            <!-- <label class="positioned">查询次数: {{ data.scanNumber }}次 </label>
            <label class="positioned positioned-error" v-if="data.exceedScanNumber && data.exceedScanNumber==1">查询次数过多 ，请核实</label>
            <p class="positioned">溯 源 I D: {{ data.tracingNo }}</p> -->
            <p class="positioned">批次编号:  {{ data.batchData.data.breedingBatchCode }}</p>
            <!-- <p class="positioned positioned-error" v-if="data.exceedPopFarmDays && data.exceedPopFarmDays==1">距离出栏时间较久，请核实</p> -->

        </div>
      </div>
    </div>

    <!-- 批次信息 -->
    <div class="batchContainer">
      <div class="batchTitle">批次信息</div>
      <div class="batchInfo">
        <div class="bacthKey">产品批次</div>
        <input class="batchValue"   v-model="batchValue" placeholder="请从产品外包装喷码处查询"  @keyup.enter="search">
        <div class="batchValue-btn" @click="search()"> 查询 </div>
      </div>
    </div>

    <div class="pushDate">
      <div class="timeDetail">
        <img class="icon"
          src="https://asset.fsytss.com/trace/wcj/icon_product_5.png "
          alt=""
        />
        <div class="dateTitle">出栏日期</div>
      </div>
      <div class="date">
        {{
          data.fattenPushPopTime ? data.formatFattenPushPopTime : "未出栏"
        }}
      </div>
    </div>
    <!-- 信息 -->
    <div class="infocontainer">
      <div class="fromAddress">
        <div style="display: flex; align-items: center">
          <img class="icon"
            src="https://asset.fsytss.com/trace/wcj/icon_product_7.png "
            alt=""
          />
          <div class="infoLittleTitle">产地</div>
        </div>
        <div class="addressTextTitle">文昌市</div>
        <div class="addressLine"></div>
        <div class="addressDetail">{{ data.sourceAddress }}</div>
      </div>
      <div class="businessAndSpecContainer">
      
        <div class="businessContainer">
          <img class="businessimg1"  src="https://asset.fsytss.com/trace/wcj/bg_product_4.png" alt="">
        <img class="businessimg2" :src="business.smallImageUrl" alt="">
          <div style="display: flex; align-items: center;z-index: 3;position: relative;">
            <img class="icon"
              src="https://asset.fsytss.com/trace/wcj/icon_product_6.png"
              alt=""
            />
            <div class="infoLittleTitle">出品企业</div>
          </div>
          <div class="addressText">{{ data.supplierName }}</div>
        </div>
        <div class="specContainer">
          <div style="display: flex; align-items: center">
            <img class="icon"
              src="https://asset.fsytss.com/trace/wcj/icon_product_8.png"
              alt=""
            />
            <div class="infoLittleTitle">产品规格</div>
          </div>
          <div class="addressText" v-if="data.batchData&&data.batchData.status==0">{{ data.batchData.data.productSpec.spec }}</div>
        </div>
      </div>
    </div>
    <!-- 产地定位 -->
    <div class="baseinfo" v-if="latitude">
      <div class="placeAddress">
        <div class="addressTag">
          <div class="titleLeftLine"></div>
          产地定位
        </div>
        <div class="address" id="container"></div>
        <div class="addressInfo" v-if="data.sourceAddress">
          <div>地址：{{ data.sourceAddress }}</div>
          <!-- <div style="text-align: center;color: #000;font-size: 13px;">联系方式：{{ mobile }}</div> -->
        </div>
        
      </div>
    </div>
    <!-- 检测报告 -->
    <div
      class="reportContainer"
      style="margin-top: 26px"
      v-if="
        data.manufactureQualityInspectFile ||
        data.manufactureAnimalQuarantineFile
      "
    >
      <div class="addressTag" style="color: #d82a1f">
        <div class="titleLeftLine2"></div>
        检测报告
      </div>
      <div>
        <!-- 轮播 -->
        <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
          <van-swipe-item v-if="data.manufactureQualityInspectFile"
            ><img
              class="bannerImg"
              :src="data.manufactureQualityInspectFile"
              alt=""
              @click="reportClick('manufactureQualityInspectFile')"
          /></van-swipe-item>
          <van-swipe-item v-if="data.manufactureAnimalQuarantineFile"
            ><img
              class="bannerImg"
              :src="data.manufactureAnimalQuarantineFile"
              alt=""
              @click="reportClick('manufactureAnimalQuarantineFile')"
          /></van-swipe-item>
        </van-swipe>
      </div>
    </div>
    <!-- 区块链信息 -->
    <div
      class="baseinfo"
      style="margin-top: 26px"
      v-if="data.formatFattenPushPopTimeTime"
    >
      <div class="addressTag2">
        <div class="titleLeftLine2"></div>
        区块链信息
      </div>

      <areaCanvasImg :isred=true  :imgtype=0 :id="data.manufactureOrderContractId " :time=" data.formatFattenPushPopTimeTime"></areaCanvasImg>

      <!-- <div class="downLoad" @click="imagePreview(url)">点击放大 ></div>
      <div class="area">
        <div  class="areaimg" ref="canvasImg">
          <div class="areaimgText">
            <div >
              海南省文昌市畜牧兽医服务中心
            </div>
            <div class="only">{{ data.manufactureOrderContractId }}</div>
            <div >
              {{ data.formatFattenPushPopTimeTime }}
            </div>
          </div>
        </div>
      </div> -->




    </div>

    <!-- 底部技术支持 -->
    <div class="bottom"> <img style="display: block;margin: 0 auto;width: 150px;" src="https://asset.fsytss.com/trace/wcj/icon_technical_support.png" alt=""></div>
    <!-- 加载loading -->
    <div class="loading_all" v-if="loadingAll">
      <van-loading type="spinner" color="#ffffff" vertical>{{
        "加载中"
      }}</van-loading>
    </div>
  </div>
</template>
  
  <script>
import Vue from "vue";
import { ImagePreview, Loading } from "vant";
import areaCanvasImg from "../../components/canvasImgComponent.vue";
import { post } from "../../utils/http";
import { getDiffDay } from "../../utils/util";
import moment from "moment";
Vue.use(ImagePreview).use(Loading);
export default {
  name: "HomeProductComponent",
  components: {
    areaCanvasImg
  },
  props: [
    "data",
    "latitude",
    "longitude",
    "url",
    "productDetailsFigureImageUrl",
    "business",
    "mobile",
    "traceCode",
  ],
  data() {
    return {
      loadingAll: false,
      batchValue:'',//输入的批次信息
      batchData:''//请求的批次信息
    };
  },
  mounted() {
    this.initMap();
    this.batchValue =   this.data.batchValue
  },
  methods: {
    search(){ // 批次查询
      this.loadingAll=true
      if(this.batchValue==''){
        this.batchValue=null
      }
      this.data.batchValue =   this.batchValue
      const url =`/api/public/v2/manufacture/tracing/findProductTraceByPopFenceOrderNo`
      const params = {
        businessId: this.data.businessId,//"0193a9551bbd0a15ff808081939edd23",
        productId: this.traceCode,//"0193a95b094805ceff808081939fea3a",
        orderNo: this.batchValue//"2025030300004"
      }
      post(url, params, false).then(res=>{
        // 两个个参数分别代表，是否有阶段数，是否有批次，是否有检测报告
        this.batchData=res
        this.data.batchData = this.batchData
        let cycleDayAll = 0;
        let stageList = res.data.breedingBatchStageList
        if(res.data.fattenPushPopTime){
          this.data.fattenPushPopTime = res.data.fattenPushPopTime
          this.data.formatFattenPushPopTime= moment(this.data.fattenPushPopTime).format(
              "yyyy年MM月DD日"
            )
        }
        stageList.forEach(item=>{
          let breedingBatchStage = item
        
          console.log(breedingBatchStage.startTime);
          let startTime = breedingBatchStage.startTime
              ? new Date(
                  moment(breedingBatchStage.startTime).format("yyyy/MM/DD") +
                    " 00:00:00"
                )
              : null;
          let endTime = breedingBatchStage.endTime
              ? new Date(
                  moment(breedingBatchStage.endTime).format("yyyy/MM/DD") +
                    " 23:59:59"
                )
              : null;
          let nowDate = new Date(
            moment(new Date().getTime()).format("yyyy/MM/DD") + " 23:59:59"
          );
          let cycleDay = 0;
          if (startTime && startTime <= nowDate.getTime()) {
            if (endTime && endTime > 0 && endTime <= nowDate.getTime()) {
              cycleDay = getDiffDay(startTime, endTime);
            } else {
              cycleDay = getDiffDay(startTime, nowDate);
            }
          }
          
          item.cycleDay = cycleDay
          cycleDayAll += cycleDay;
        })
        this.data.cycleDay = cycleDayAll
        this.data.manufactureQualityInspectFile  = res.data.manufactureQualityInspectFile
        this.data.manufactureAnimalQuarantineFile  = res.data.manufactureAnimalQuarantineFile
        this.$emit('getstageList', stageList);
        this.$nextTick(() => {
          this.initMap()
        })
        this.loadingAll=false
      }).catch(err=>{
        this.batchData=err
        this.data.batchData = this.batchData
        this.$emit('getstageList', null,false);
        this.loadingAll=false
        alert('真品验证不通过');
      })
    },
    reportClick(img){
      let arr=[]
      if(this.data.manufactureQualityInspectFile){
        arr.push(this.data.manufactureQualityInspectFile)
      }
      if(this.data.manufactureAnimalQuarantineFile){
        arr.push(this.data.manufactureAnimalQuarantineFile)
      }
      let index=0
      if(arr.length==1){
        index=0
      }else if(img=='manufactureAnimalQuarantineFile'){
        index=1
      }
      ImagePreview({
        images: arr,
        startPosition: index,
      });

    },
    async createImage() {
      try {
        // const canvas = await html2canvas(this.$refs.canvasImg); // 在这里，将需要转换成图片的部分作为参数传入html2canvas
        // const image = canvas.toDataURL(); // 将生成的canvas转换为DataURL格式
        // this.imgUrl = image;
        // ImagePreview([image]);
        var canvas2 = document.createElement("canvas");
        let _canvas = this.$refs.canvasImg;
        var w = parseInt(window.getComputedStyle(_canvas).width);
        var h = parseInt(window.getComputedStyle(_canvas).height);
        //将canvas画布放大若干倍，然后盛放在较小的容器内，就显得不模糊了
        canvas2.width = w * 2;
        canvas2.height = h * 2;
        canvas2.style.width = w + "px";
        canvas2.style.height = h + "px";
        const context = canvas2.getContext("2d");
        context.scale(1,1);
        context.fillStyle = '#FFFFFF'
        context.fillRect(0, 0, canvas2.width, canvas2.height);


        const config = {
          backgroundColor: null,
          canvas: canvas2,
          useCORS: true,//配置允许跨域
          scale: 2,
          dpi: 800 // 处理模糊问题
        };
        console.log("获取指定的宽高", w, h, canvas2);

        const canvas = await html2canvas(this.$refs.canvasImg,config).then(canvas => {
         
         
          try {
              // 转成图片，生成图片地址
               this.imgUrl = canvas.toDataURL("image/png");  
                ImagePreview([this.imgUrl]);
            } catch (e) {
              alert("图片跨域,保存失败");
            }


        });

        this.loadingAll = false;
      } catch (e) {
        this.loadingAll = false;
        throw new Error(e);
      }
    },
    imagePreview(url) {
      this.loadingAll = true;
      this.createImage();
    },
    downloadImage(url, filename) {
      // 创建隐藏的可下载链接
      const element = document.createElement("a");
      element.setAttribute("href", url);
      element.setAttribute("download", filename);
      // 触发点击
      document.body.appendChild(element);
      element.click();
      // 然后移除
      document.body.removeChild(element);
    },
    initMap() {
      console.log('initMap:',this.latitude);
      
      if(!this.latitude) return
      let latitude = Number(this.latitude);
      let longitude = Number(this.longitude);
      const center = new TMap.LatLng(latitude, longitude); //设置地图中心点坐标
      this.map = new TMap.Map("container", {
        center: center,
        zoom: 12, //设置地图缩放级别
        baseMap: {
          type: "vector", //类型：失量底图
          // features: ["base", "building2d"]
        },
      });
      var markerLayer = new TMap.MultiMarker({
        map: this.map, //指定地图容器
        styles: {
          myStyle: new TMap.MarkerStyle({
            width: 25, // 点标记样式宽度（像素）
            height: 35, // 点标记样式高度（像素）
            // "src": '../assets/images/icon_no.png',  //图片路径
            //焦点在图片中的像素位置，一般大头针类似形式的图片以针尖位置做为焦点，圆形点以圆心位置为焦点
            anchor: { x: 16, y: 32 },
          }),
        },
        geometries: [
          {
            id: "1", //点标记唯一标识，后续如果有删除、修改位置等操作，都需要此id
            styleId: "myStyle", //指定样式id
            position: new TMap.LatLng(latitude, longitude), //点标记坐标位置
            properties: {
              //自定义属性
              title: "marker1",
            },
          },
        ],
      });
    },
  },
};
</script>
  
  <style lang="less" scoped>
.image {
  width: 375px;
  height: 240px;
  margin: 0 auto;
  display: block;
}
.baseInfo {
  background-color: #fff;
  margin: 0 12px;
  padding: 0px 0 16px 0;
  position: relative;
  border-radius: 6px;
}

.info_bottom {
  width: 319px;
  //  height: 150px;
  display: flex;
  background: url("https://asset.fsytss.com/trace/wcj/bg_verify_2.png")
    no-repeat center / contain;
  border-radius: 6px;
  // padding: 9px 19px;
  padding-bottom: 10px;
  padding-left: 7px;
  margin: 0 auto;
  margin-top: -30px;
  position: relative;
  background-size:cover;
  margin-bottom: 16px;
  z-index: 2;
  .info_bottomImg {
    width: 69px;
    height: 93px;
     margin-top: -5px;
  }
  .info_bottomLeft {
    width: 250px;
    margin-left: 8px;
    margin-top: 15px;
    overflow: hidden;
    .info_bottomTitle {
      font-size: 15px;
      color: #231815;
      font-weight: bold;
      line-height: 16px;
      margin-bottom: 8px;
    }
    .info_bottomLeftDetail {
      display: flex;
      color: #000;
      font-size: 10px;
      color: #231815;
      line-height: 17px;
    }
    label,p{
      margin: 0px auto;
      font-size: 10px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .title{
      font-size: 13px;
      margin-bottom: 5px;
      font-weight: bold;
    }
    .positioned{
      
      margin-bottom: 3px;
    }
    .positioned-error{

    color: red;
    }

  }
}
.infotag {
  position: relative;
  width: 95px;
  height: 23px;
  // background: url("../../assets/images/project1/infoTag.png") no-repeat center /
  //   cover;
  background-image: linear-gradient(
    -135deg,
    rgb(192, 73, 51),
    rgb(226, 187, 125)
  );
  border-radius: 5px 16px 16px 0px;
  font-weight: bold;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin-top: 11px;
}
.infoItem {
  width: 100%;
  margin-top: 15px;
  padding: 0 16px;
}
.line {
  height: 0.5px;
  background-color: rgb(139, 202, 157);
  margin-top: 10px;
}
.infodetail {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
  color: #666666;
  line-height: 20px;
}
.pushDate {
  width: 319px;
  height: 87px;
  margin: 0 auto;
  background: url("https://asset.fsytss.com/trace/wcj/bg_product_1.png")
    no-repeat center / cover;
  padding: 16px;
  margin-bottom: 8px;
  // pushDate
}
.timeDetail {
  display: flex;
  align-items: center;
}
.dateTitle {
  font-weight: 500;
  font-size: 12px;
  color: #666666;
  line-height: 18px;
  margin-left: 4px;
}
.date {
  font-weight: 400;
  font-size: 19px;
  color: #231815;
  // line-height: 30px;
}
.icon{

  width: 18px;
}
// 信息
.infocontainer {
  width: 319px;
  height: 197px;
  margin: 0 auto;
  display: flex;
  margin-bottom: 23px;
}
.infoLittleTitle {
  line-height: 20px;
  color: #666666;
  font-weight: 500;
  font-size: 13px;
  margin-left: 4px;
}
.fromAddress {
  width: 139px;
  height: 100%;
  margin-right: 8px;
  background: url("https://asset.fsytss.com/trace/wcj/bg_product_2.png")
    no-repeat center / cover;
  padding: 11px 15px;
}
.businessAndSpecContainer {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
 
}
.businessContainer {
  width: 172px;
  position: relative;
  height: 95px;
  border-radius: 4px;
  overflow: hidden;
  // background: url("../../assets/images/project2/businessBackground.png")
  //   no-repeat center / cover;
  padding: 11px 10px;
  box-sizing: border-box;
  .businessimg1{
    position: absolute; width: 100%; top: 0;left: 0;
    z-index: 2;
  }
  .businessimg2{
    position: absolute; width: 100%;height: 100%; top: 0;left: 0;
    z-index: 1;
  }
}
.specContainer {
  border-radius: 4px;
  width: 172px;
  height: 95px;
  background: url("https://asset.fsytss.com/trace/wcj/bg_product_3.png")
    no-repeat center / cover;
  padding: 11px 10px;
}
.addressTextTitle{
  font-size: 14px;
  color: #231815;
  line-height: 21px;
}
.addressText {
  font-size: 12px;
  color: #231815;
  line-height: 21px;
  position: relative;
  z-index: 3;
}
.addressLine {
  width: 109px;
  height: 1px;
  background-color: red;
  margin: 2px 0;
}
.addressDetail {
  font-size: 12px;
  color: #231815;
  line-height: 15px;
}
// 产地定位
.addressTag {
  position: relative;
  width: 95px;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  color: #ffffff;
  display: flex;
  align-items: center;
  z-index: 2;
  margin: 30px 0 12px 28px;
}
.addressTag2 {
  position: relative;
  width: 95px;
  font-weight: bold;
  font-size: 16px;
  line-height: 13px;
  color: #d82a1f;
  display: flex;
  align-items: center;
  z-index: 2;
  margin: 30px 0 -12px 28px;
}

.titleLeftLine {
  width: 3px;
  height: 16px;
  background-color: #fff;
  margin-right: 8px;
  border-radius: 6px;
}
.titleLeftLine2 {
  width: 3px;
  height: 16px;
  background-color: #d82a1f;
  margin-right: 8px;
  border-radius: 6px;
}
.placeAddress {
  position: relative;
  background-color: #fff;
  background-image: linear-gradient(to bottom, #ff8c42, #c00000);
  overflow: hidden;
  padding-bottom: 30px;
}
.address {
  height: 400px;
  margin: 0 28px;
  border-radius: 5px;
  overflow: hidden;
}
.addressInfo {
  width: 299px;
  text-align: center;
  font-size: 13px;
  color: #000;
  line-height: 16px;
  text-align: center;
  position: absolute;
  bottom: 80px;
  left: calc(50% - 150px);
  border-radius: 32px;
  box-shadow: 0 0 5px 0.1px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  padding: 6px 18px;
  box-sizing: border-box;
}
.bottom {
  margin-top: 11px;
  text-align: center;
  font-size: 12px;
  color: #595757;
  line-height: 20px;
  opacity: 0.8;
  padding-bottom: 11px;
}
.areaimg {
  margin: 9px auto;
  width: calc(100vw - 28px * 2 - 9px * 2);
  height: calc((100vw - 28px * 2 - 9px * 2) / 1.402);
  // background: url("https://asset.fsytss.com/trace/wcj/bg_certificate_red_order.png")
  //   no-repeat center / cover;
  background: url("../../assets/images/project1/areaRedOrder.png") no-repeat
    center / cover;
}
.areaimgText {
  padding-top: 69px;//18.2
  padding-left: 90px;
  padding-right: 25px;
  text-align: right;
  div {
    font-size: 12px;
    word-break: break-all;
    color: #231815;
    line-height: 16px;
  }
  .only{
    margin: 8px 0 4px 0;
  }
}
.area {
  position: relative;
  background: #fff; 
  padding: 0 9px;
  margin: 0 28px;
  z-index: 1;
  overflow: hidden;
  border-radius: 5px;
}
.downLoad {
  color: #d82a1f;
  font-size: 13px;
  padding: 0 28px;
  text-align: right;
  margin-bottom: 7px;
}
.reportContainer {
  .my-swipe {
    background-image: linear-gradient(to bottom,#f5bf6c,  #bd442f);
    margin: 0 28px;
    border-radius: 6px;
    .van-swipe-item {
      width: 319px !important;
      height: 222px !important;
      color: #fff;
      font-size: 20px;
      line-height: 150px;
      text-align: center;
      // margin-top: 24px;
      padding: 11px 41px;
      border-radius: 5px;
      overflow: hidden;
      .bannerImg {
        max-width: 200px;
        height: 100%;
        max-height: 200px;
        object-fit: contain;
      }
    }
  }
}

// 批次信息
.batchContainer{
  width: 319px;
  background-color: #fff;
  margin: auto;
  margin-top: 34px;
  border-radius: 8px;
  // display: inline-block;
  .batchTitle{
    position: relative;
  width: 95px;
  height: 23px;
  background-image: linear-gradient(
    -135deg,
    #f79a68,
    rgb(238, 29, 14)
  );
  border-radius: 5px 16px 16px 0px;
  font-weight: bold;
  font-size: 13px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  margin-top: -11px;
  }
  .batchInfo{
    display: flex;
    margin-top: 14px;
    margin-bottom: 21px;
    justify-content: center;
    align-items: center;
    padding-bottom: 30px;
    .bacthKey{
        color: #006000;
        font-size: 12px;
        letter-spacing: 1px;
    }
    .batchValue{
        width: 170px;
        height: 26px;
        border: 1px solid #cccccc;
        text-align: center;
        font-size: 12px;
    //     display: flex;
    // justify-content: center;
    // align-items: center;
    margin-left: 8px;
    border-radius: 6px;
    background-color: #f7f7f7;
    }
    .batchValue-btn{
      background-image: linear-gradient(
        135deg,
        
        #f79a68,
        rgb(238, 29, 14)
      );
      width: 50px;
      height: 26px;
      margin-left: 10px;
      border-radius: 4px;
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;

    }
  }
}
</style>